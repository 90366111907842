@use 'sass:color';

@use './_colors.scss';
@use '@material/theme' with (
  $primary: colors.$primary,
  $secondary: colors.$secondary,
  $background: colors.$background
);
@use 'raincoat/css/_raincoat-base.scss';

@use '@material/banner';
@use '@material/banner/styles';
@use '@material/button/mdc-button';
@use '@material/button';
@use '@material/card';
@use '@material/checkbox/mdc-checkbox';
@use '@material/data-table/data-table';
@use '@material/dialog';
@use '@material/drawer';
@use '@material/fab';
@use '@material/form-field/_index.scss' as form-field;
@use '@material/form-field/mdc-form-field';
@use '@material/icon-button';
@use '@material/icon-button/styles' as icon-button-styles;
@use '@material/image-list';
@use '@material/image-list/mdc-image-list';
@use '@material/linear-progress';
@use '@material/list/mdc-list';
@use '@material/menu-surface/mdc-menu-surface';
@use '@material/menu/mdc-menu';
@use '@material/radio';
@use '@material/radio/styles' as radio-styles;
@use '@material/radio/radio-theme' as radio-theme;
@use '@material/slider/styles' as slider-styles;
@use '@material/select/mdc-select';
@use '@material/select/styles' as selectStyles;
@use '@material/tab-bar/mdc-tab-bar';
@use '@material/tab-scroller/mdc-tab-scroller';
@use '@material/tab-indicator/mdc-tab-indicator';
@use '@material/tab/mdc-tab';
@use '@material/textfield/icon';
@use '@material/textfield/mdc-text-field';
@use '@material/tooltip/styles' as tooltip-styles;
@use '@material/top-app-bar/mdc-top-app-bar';
@use '@material/typography';
@use '@material/typography/mdc-typography';

@include card.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
@include dialog.core-styles;
@include drawer.core-styles;
@include drawer.dismissible-core-styles;
@include drawer.modal-core-styles;
@include fab.core-styles;
@include form-field.core-styles;
@include linear-progress.core-styles;

$primary-button: darkgoldenrod;
$warning-button: orangered;

body {
  height: 100%;
  margin: 0;

  .mdc-top-app-bar__title {
    font-weight: 900;
    color: theme.$background !important;
  }

  .mdc-top-app-bar__action-item {
    font-weight: 900;
    color: theme.$background !important;
  }
}

h1 {
  @include typography.typography(headline4);
}

h2 {
  @include typography.typography(headline5);
}

h3 {
  @include typography.typography(headline5);
}

h4,
h5,
h6 {
  @include typography.typography(headline5);
}

h5 {
  font-size: 1.35rem;
}

h6 {
  font-size: 1.3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: theme.$secondary;
}

a,
div,
label,
li,
p,
span:not(.mdc-button__label),
td,
th,
.mdc-data-table__cell {
  @include typography.typography(body1);
}

strong a,
div,
label,
li,
p,
span,
td,
th,
.mdc-data-table__cell {
  font-weight: unset;
}

a {
  color: theme.$primary;
}

.disabled-link-button {
  background-color: gray !important;
  pointer-events: none;
}

form {
  max-width: 480px;

  .mdc-button {
    margin-top: 18px;
  }
}

#dialog-content {
  min-height: 20rem;
}

fieldset {
  max-width: 460px;
  margin-top: 24px;

  .fieldset-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    .mdc-tooltip-wrapper--rich {
      margin-left: 0.35rem;
      button {
        height: 1.75rem;
        width: 1.75rem;
      }
    }

    .mdc-tooltip-footer {
      color: theme.$secondary;
    }
  }
}

.accessible-h1 {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  white-space: nowrap;
  width: 1px;
  height: 1px;
}

.mdc-form-field,
.mdc-text-field {
  display: flex;
}

fieldset,
form {
  fieldset,
  > .mdc-select,
  > .mdc-text-field,
  .fields-wrapper .mdc-select {
    margin-top: 24px;
  }
}

td .mdc-text-field {
  margin-top: 0;
}

#body-content {
  max-width: 856px;
  margin: auto;
  padding: 2em;
  padding-bottom: 48px;
  position: relative;
  min-height: 80vh;
}

#footer {
  max-width: 855px;
  margin: auto;
  margin-bottom: 8px;
  text-align: center;
}

.display-none,
.hide {
  display: none;
}

.center-text {
  text-align: center;
}

.mdc-card {
  margin: 1rem 0;
}

.mdc-radio {
  @include radio-theme.disabled-unchecked-stroke-color(#ccc);

  &.mdc-radio--disabled + label {
    color: #ccc;
  }
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: inline-block !important;
}

// when jumping to an anchor, don't scroll behind fixed navbar
.anchor-header {
  scroll-margin-top: 64px;
}

.mdc-button.mdc-button--touch.mdc-button--raised > .mdc-button__label {
  color: theme.$background;
}

.mdc-button__label {
  font-weight: 900;
}

.mdc-button.mdc-button--touch.mdc-button--raised i {
  color: theme.$background;
}

.mdc-button.info {
  margin: 0;
  min-width: unset;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;

  .mdc-button__icon {
    margin: 0;
    height: 1rem;
  }
}

.mdc-tooltip__content {
  overflow: scroll;
}

i {
  color: theme.$secondary;
}

.mdc-banner__graphic i {
  color: unset;
}

.primary-button {
  @include button.container-fill-color(theme.$secondary);
}

.warning-button {
  @include button.container-fill-color($warning-button);
}

.toggle-buttons {
  display: flex;

  .toggle-button {
    button {
      background-color: white;
      font-size: inherit;
      border: 2px solid lightgray;
      border-left: none;
      padding-block: unset;
      padding-inline: unset;
      padding: 1em;
      cursor: pointer;
      white-space: nowrap;
      font-weight: bold;

      &:active {
        background-color: theme.$primary;
        color: theme.$background;
      }
    }

    &:nth-child(1) {
      button {
        border-left: 2px solid lightgray;
      }
    }

    &.toggle-active {
      button {
        background-color: theme.$primary;
        color: theme.$background;
      }
    }
  }
}

.text-wrap {
  white-space: normal;
}

.accordion {
  overflow: hidden;

  .tab {
    overflow: hidden;

    input.tab-toggle {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    input.tab-toggle:checked {
      ~ .tab-label {
        .accordion-button {
          transform: rotate(-180deg);
          transition: all 0.5s;
        }
      }

      ~ .tab-content {
        max-height: 100em;
        transition: all 2s;
        // https://www.drupal.org/project/flexslider/issues/1748514
        -webkit-transform: translate3d(0px, 0px, 1px);
      }
    }

    &-label {
      display: flex;
      justify-content: flex-start;
      padding-left: 4px;

      .accordion-button {
        line-height: 2em;
        transform-origin: 0.3em 1em;
        transition: all 0.5s;
        cursor: pointer;
      }
    }

    &-content {
      max-height: 0;
      padding: 0 1em;
      transition: all 0.5s;
      overflow: hidden;
    }
  }

  .tab-content {
    padding: 0;
  }

  .accordion-label {
    margin-left: 10px;
  }
}

.mdc-text-field-helper-text--persistent {
  color: colors.$highlighted !important;
}
